.unique-contact-page {
    color: #333;
    max-width: 1200px;
    margin: 2rem auto;
    line-height: 1.8;
    padding: 2rem;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    background-color: var(--neutral-light);
}

.unique-contact-intro {
    text-align: center;
    padding: 2rem;
    background: linear-gradient(45deg, var(--primary), var(--neutral-dark));
    color: var(--neutral-light);
    border-radius: 10px;
    margin-bottom: 2rem;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.unique-contact-intro h1 {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    color: var(--neutral-light);
}

.unique-contact-intro p {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
}

.unique-contact-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    /* Updated this line */
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.unique-visit-us,
.unique-contact-us {
    flex: 0 0 45%;
}

.unique-visit-us h2,
.unique-contact-us h2 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    color: var(--neutral-dark);
}

.unique-visit-us address,
.unique-contact-us p {
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
}

.unique-contact-us a {
    color: var(--primary);
    text-decoration: none;
}

.unique-contact-us a:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .unique-contact-page {
        padding: 1rem;
    }

    .unique-contact-content {
        flex-direction: column;
        display: flex;
        align-items: center;
    }

    .unique-visit-us,
    .unique-contact-us {
        flex: 1;
        margin-bottom: 2rem;
        text-align: center;
    }

    .unique-contact-us:last-child {
        margin-bottom: 0;
    }
}