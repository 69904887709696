.unique-about-page {
    color: #333;
    max-width: 1200px;
    margin: 2rem auto;
    line-height: 1.8;
    padding: 2rem;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.unique-about-intro {
    text-align: center;
    padding: 4rem 2rem;
    background: linear-gradient(45deg, var(--primary), var(--neutral-dark));
    color: var(--neutral-light);
    border-radius: 10px;
    margin-bottom: 2rem;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.unique-about-intro h1 {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    color: var(--neutral-light);
}

.unique-about-intro p {
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
    text-align: left;
}

.unique-section-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4rem 2rem;
    background-color: var(--neutral-light);
    border-radius: 10px;
    margin-bottom: 2rem;
}

.unique-mission-text {
    flex: 0 0 50%;
    /* Adjusted to 50% */
    text-align: left;
}

.unique-mission-image {
    flex: 0 0 45%;
    /* Adjusted to 45% */
    padding-left: 2rem;
}

.unique-mission-image img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.unique-faq-section {
    padding: 4rem 2rem;
    background-color: var(--neutral-light);
    border-radius: 10px;
}

.unique-faq-item {
    padding: 16px;
    margin-bottom: 1.4rem;
    background: var(--neutral-dark);
    border-radius: 12px;
    color: var(--neutral-light);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.unique-faq-item:hover {
    transform: translateY(-5px);
    background: var(--primary);
}

.unique-faq-item h3 {
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

@media (max-width: 768px) {
    .unique-about-page {
        padding: 1rem;
    }

    .unique-section-content {
        flex-direction: column;
    }

    .unique-mission-text,
    .unique-mission-image {
        flex: 1;
        padding: 0;
        margin-bottom: 2rem;
    }
}