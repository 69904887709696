.footer {
    background-color: #333;
    color: #fff;
    padding: 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}

.footer-section {
    flex: 1;
    min-width: 200px;
    margin: 1rem;
    text-align: center;
}

.footer h4 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.footer p {
    font-size: 1rem;
    line-height: 1.6;
}

.cta-button {
    display: inline-block;
    margin-top: 1rem;
    padding: 10px 20px;
    border: 2px solid #fff;
    border-radius: 30px;
    background-color: transparent;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.cta-button:hover {
    background-color: #fff;
    color: #333;
}

.footer-bottom {
    width: 100%;
    margin-top: 2rem;
    border-top: 1px solid #fff;
    padding-top: 1rem;
    text-align: center;
}

@media (max-width: 768px) {
    .footer {
        flex-direction: column;
        align-items: center;
    }
}