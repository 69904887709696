.landing-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3));
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    height: 80vh;
    position: relative;
    color: white;
}

.landing-container::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.60);
    z-index: 1;
}

.text-section {
    position: relative;
    z-index: 2;
    text-align: center;
    padding: 20px;
}

.title {
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.sub-title {
    font-size: 1.2rem;
    margin-bottom: 20px;
}

.assessment-button {
    padding: 10px 20px;
    background: var(--primary);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.assessment-button:hover {
    background: var(--accent);
    transform: scale(1.05);
    font-weight: bold;
}

@keyframes slideUp {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.title,
.sub-title,
.assessment-button {
    opacity: 0;
    transform: translateY(50px);
    animation: slideUp 1s forwards;
}

.title {
    animation-delay: 0.2s;
}

.sub-title {
    animation-delay: 0.4s;
}

.assessment-button {
    animation-delay: 0.6s;
}