.info-sections-container {
    display: flex;
    flex-direction: column;
    margin: 60px 10%;
}

.info-section {
    display: flex;
    flex-direction: row;
    margin: 40px 0;
    align-items: center;
}

.info-section:nth-child(even) {
    flex-direction: row-reverse;
}

.info-image {
    flex: 1;
    width: 50%;
    min-width: 380px;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 12px;
}

.info-text {
    flex: 1;
    padding: 40px;
    background: var(--light-background);
    border-radius: 8px;
    color: var(--neutral-dark);
}

.info-text h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: var(--primary);
}

.info-text p {
    font-size: 1rem;
    margin-bottom: 15px;
    line-height: 1.6;
}

.info-text ul {
    padding-left: 40px;
    margin-bottom: 15px;
}

.info-text ul li {
    margin-bottom: 8px;
    font-size: 1rem;
}

.intro-text {
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--primary-text);
    margin: 0 auto 40px;
    max-width: 1100px;
    text-align: center;
    line-height: 1.8;
}

.service-list {
    list-style-type: none;
    padding: 0;
}

.service-list li {
    font-weight: bold;
    margin-bottom: 10px;
}

.text-container {
    padding: 30px;
    background-color: var(--neutral-light);
    border-radius: 5px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    margin-right: 20px;
}

.image-container {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

@media screen and (max-width: 768px) {
    .info-sections-container {
        margin: 30px 5%;
        /* Reduced the margin to increase the width */
    }

    .info-section {
        flex-direction: column;
        margin: 20px 0;
        /* Adjusted margin for mobile */
    }

    .info-section:nth-child(even) {
        flex-direction: column;
    }

    .info-image {
        width: 100%;
        /* Image will take full width on mobile */
        min-width: auto;
        /* Remove the minimum width constraint on mobile */
        margin: 12px 0;
        /* Adjusted margin for mobile */
    }

    .info-text {
        padding: 20px;
        /* Reduced padding to increase the width */
    }

}

@keyframes fadeSlideIn {
    0% {
        transform: translateY(50px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.fade-slide-in {
    animation: fadeSlideIn 1s forwards;
}