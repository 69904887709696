.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px;
    position: relative;
    background-color: var(--neutral-light);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.logo {
    color: var(--neutral-dark);
    font-weight: bold;
    cursor: pointer;
    height: 100px;
    margin-top: -20px;
}

.menu {
    list-style-type: none;
    padding: 0;
    display: flex;
    gap: 20px;
}

.menu a {
    text-decoration: none;
    color: var(--neutral-dark);
    transition: color 0.2s;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 5px;
}

.menu a:hover {
    color: var(--primary);
    background-color: rgba(0, 0, 0, 0.05);
}

.hamburger {
    display: none;
    flex-direction: column;
    gap: 5px;
    cursor: pointer;
}

.hamburger span {
    width: 25px;
    height: 3px;
    background-color: var(--neutral-dark);
    display: block;
    transition: 0.3s;
}

.concel {
    border: var(--neutral-dark) solid .5px;
    padding: 10px;
    border-radius: 12px;
    font-weight: 100;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.concel:hover {
    background: var(--neutral-dark);
    color: var(--neutral-light);
}

@media (max-width: 768px) {
    .menu {
        position: absolute;
        top: 85%;
        right: 0;
        background-color: var(--neutral-light);
        flex-direction: column;
        gap: 25px;
        padding: 25px 30px;
        display: none;
        z-index: 1000;
        border-top: 1px solid var(--secondary);
        width: 100%;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        transition: max-height 0.3s ease-in-out;
        max-height: 0;
        overflow: hidden;
    }

    .menu.active {
        display: block;
        max-height: 450px;
        
    }

    .menu li {
        padding-bottom: 15px;
    }

    .menu a {
        color: var(--neutral-dark);
        font-size: 1.2rem;
    }

    .hamburger {
        display: flex;
    }

    .hamburger span:nth-child(1) {
        transform: rotate(0);
        transition: transform 0.3s;
    }

    .hamburger span:nth-child(2) {
        opacity: 1;
        transition: opacity 0.3s;
    }

    .hamburger span:nth-child(3) {
        transform: rotate(0);
        transition: transform 0.3s;
    }

    .hamburger.active span:nth-child(1) {
        transform: rotate(45deg) translateY(6px);
    }

    .hamburger.active span:nth-child(2) {
        opacity: 0;
    }

    .hamburger.active span:nth-child(3) {
        transform: rotate(-45deg) translateY(-6px);
    }
}