.services-page {
    color: #333;
    max-width: 1200px;
    margin: 2rem auto;
    line-height: 1.6;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    animation: fadeIn 1s ease;
    padding: 2rem;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.services-intro {
    text-align: center;
    padding: 4rem 0;
    color: #fff;
    border-radius: 10px;
    background: linear-gradient(45deg, var(--primary), var(--neutral-dark));
    
}

.services-intro h1 {
    font-size: 3.5rem;
    margin-bottom: 1rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.section-content {
    padding: 4rem 0;
}

.services-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 2rem;
}

.service-card {
    background: #fff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease;
}

.service-card:hover {
    transform: translateY(-10px);
}

.service-icon {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: var(--primary);
}

.personalized-services {
    margin-top: 4rem;
    background: #f9f9f9;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.contact-section {
    text-align: center;
    padding: 4rem 0;
    background-color: var(--neutral-dark);
    color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.contact-button {
    padding: 1rem 2rem;
    background-color: #fff;
    color: var(--primary);
    border-radius: 4px;
    font-size: 1.2rem;
    cursor: pointer;
    border: none;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.contact-button:hover {
    background-color: var(--primary);
    color: #fff;
}

@media (max-width: 768px) {
    .services-grid {
        grid-template-columns: 1fr;
    }
}