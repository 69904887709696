@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(50px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.getInfo {
    padding: 50px;
    background-color: var(--neutral-light);
    opacity: 0;
    visibility: hidden;
    animation-name: fadeIn;
    animation-duration: 1.5s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.getInfo.visible {
    opacity: 1;
    visibility: visible;
}

.getInfo h2 {
    font-size: 2.5rem;
    color: var(--primary);
    margin-bottom: 20px;
    text-align: center;
}

.getInfo p {
    font-size: 1.1rem;
    color: var(--neutral-dark);
    margin-bottom: 30px;
    text-align: center;
}

.form-group {
    margin-bottom: 25px;
    position: relative;
    max-width: 60%;
    margin-left: auto;
    margin-right: auto;
}

.form-group label {
    font-size: 1.1rem;
    position: absolute;
    top: 10px;
    left: 15px;
    color: var(--neutral-dark);
    background-color: var(--neutral-light);
    padding: 0 10px;
    transition: all 0.3s;
}

.form-group input {
    width: 100%;
    padding: 20px 15px;
    font-size: 1rem;
    border-radius: 10px;
    border: 1px solid var(--neutral-dark);
    transition: all 0.3s ease;
    outline: none;
}

.form-group input:focus {
    border-color: var(--primary);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-group input:focus+label,
.form-group input:not(:placeholder-shown)+label {
    top: -15px;
    left: 10px;
    font-size: 0.9rem;
    color: var(--primary);
}

.location-button {
    padding: 12px 20px;
    background-color: var(--primary);
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.location-button:hover {
    background-color: var(--neutral-dark);
}

/* Media query for mobile view */
@media (max-width: 768px) {
    .form-group {
        max-width: 90%;
        /* Adjust this value as per your design needs. 90% will leave a little space on the sides. */
    }
}