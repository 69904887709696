.testimony-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    padding: 2rem;
    background-color: #f9f9f9;
    margin-top: -40px;
}

.testimony-div {
    background-color: #f9f9f9;
}

.testimony-card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin: 1rem auto;
    /* Center the card on mobile */
    padding: 1rem;
    width: 300px;
    background-color: #ffffff;
    text-align: center;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    animation: pulse 5s infinite ease-in-out;
}

.testimony-card:hover {
    transform: scale(1.25);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
    animation-play-state: paused;
    z-index: 1;
}

.testimony-card:nth-child(odd) {
    animation-delay: 2.5s;
}

.testimony-image {
    width: 40%;
    border-radius: 20%;
    margin-bottom: 1rem;
}

.testimony-name {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0.5rem 0;
}

.testimony-country {
    font-size: 0.9rem;
    color: #888888;
    margin-bottom: 1rem;
}

.testimony-quote {
    font-size: 1rem;
    color: #555555;
    font-style: italic;
}

@media (max-width: 768px) {
    .testimony-section {
        flex-direction: column;
        align-items: center;
        padding-left: 0;
        /* Reset left padding */
        padding-right: 0;
        /* Reset right padding */
    }
}

.testimony-title {
    font-size: 1.2rem;
    color: var(--neutral-dark);
    text-align: center;
    padding: 1px;
}

@media (max-width: 768px) {
    .testimony-title {
        font-size: 1.4rem;
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.03);
    }

    100% {
        transform: scale(1);
    }
}